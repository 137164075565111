<template>
    <form class="form" :class="{ 'form--compact': !isAtleastMd }" @submit.prevent>
        <slot></slot>
    </form>
</template>

<script>
import { vueWindowSizeMixin } from "vue-window-size";
import { useBreakPoints } from "@feedbackcompany/feedback-company-vue-components/src/util/useBreakPoints";

const { isAtLeast } = useBreakPoints();

export default {
    name: "SettingsForm",
    mixins: [vueWindowSizeMixin],
    computed: {
        isAtleastMd() {
            return isAtLeast("md", this.windowWidth);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $grey_bg;
    padding-bottom: 18px;

    &.form--compact {
        padding-left: 16px;
        padding-right: 16px;
    }
}
</style>
