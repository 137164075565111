import { required, email, requiredIf } from "@/validation/validators";

export const getScheduleReportSchema = (settings) => ({
    report: {
        dashboard: { required },
        startDate: { required },
        frequency: { required },
        recipientEmails: {
            $each: {
                email,
                required: requiredIf(() => {
                    const isOnlyOne = settings.recipientEmails.length === 1;
                    const areAllEmpty = settings.recipientEmails.join("") === "";
                    return isOnlyOne || areAllEmpty;
                }),
            },
        },
    },
});
