/**
 * @param dataURL - A DOMString containing an object URL that can be used to reference the contents of the specified source object.
 * @returns {Promise<unknown>}
 */
export const resolveImageFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.src = dataURL;
    });
