<template>
    <div class="generic_page">
        <div class="error_container">
            <ErrorNotification
                v-for="error in errors"
                :key="error.id"
                :message="error.message"
                :isWarning="error.isWarning"
                :color="error.color"
            >
                <template v-slot:icon v-if="error.icon">
                    <FontAwesomeIcon :icon="['far', error.icon]" />
                </template>
            </ErrorNotification>
        </div>
        <SettingsHeader class="generic_page__header" v-bind="headerProps" />
        <main class="generic_page__main">
            <LoaderCircular v-if="loading" class="loader" />
            <slot v-else></slot>
        </main>
    </div>
</template>

<script>
import { faExclamationCircle, faClone } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SettingsHeader from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Settings/SettingsHeader.vue";
import ErrorNotification from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/ErrorNotification.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { mapState } from "vuex";

library.add(faExclamationCircle, faClone);

export default {
    name: "GenericPage",
    components: {
        SettingsHeader,
        LoaderCircular,
        ErrorNotification,
        FontAwesomeIcon,
    },
    computed: {
        ...mapState({
            errors: (state) => state.errorStore.errors,
        }),
    },
    props: {
        headerProps: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";

.generic_page {
    @include viewportHeight(100, 54px);
    @media (max-width: 1000px) {
        @include viewportHeight(100, 48px);
    }
}
.generic_page__main {
    display: flex;
    justify-content: center;

    @include media(">=tablet") {
        padding: 0 24px;
    }
    @include media($isPhone...) {
        padding: 0;
    }
}
.loader {
    margin-top: 40px;
}
.error_container {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 1px;
    background-color: $white;
}
</style>
