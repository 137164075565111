<template>
    <GenericPage
        :headerProps="{
            title: $t('cp__reports_schedule_report'),
            subtitle: $t('cp__reports_schedule_report__subtitle'),
            backButtonTo: { path: backButtonUrl },
        }"
        :loading="isLoading"
    >
        <SettingsForm v-if="!isLoading" class="settings_form">
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__reports_schedule_report__form_title')"
            >
                <SettingsSection class="settings_fieldset" label="" maxWidth="400px">
                    <div class="report_field">
                        <div class="report_field_title">
                            <span>{{ $t("cp__reports_schedule_report_dashboard") }}</span>
                        </div>
                        <div>
                            <span class="subtitle2">{{
                                $t("cp__reports_schedule_report_dashboard_info")
                            }}</span>
                        </div>
                        <Dropdown
                            v-model="report.dashboard"
                            :searchAble="dashboards.length > 5"
                            :placeholder="$t('cp__reports_schedule_report_dashboard_choose')"
                            :options="dashboards"
                            enableExternalValidation
                            :externalValidationError="$v.report.dashboard.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.report.dashboard)
                            "
                        />
                    </div>
                    <div class="report_field">
                        <div class="report_field_title">
                            <span>{{ $t("cp__reports_schedule_report_name") }}</span>
                        </div>

                        <div>
                            <span class="subtitle2">{{
                                $t("cp__reports_schedule_report_name_info")
                            }}</span>
                        </div>

                        <TextInput
                            v-model="report.name"
                            :label="$t('cp__share__company_page__details___name')"
                        />
                    </div>
                    <div class="report_field">
                        <div class="report_field_title">
                            <span>{{ $t("cp__reports_schedule_report_date") }}</span>
                        </div>

                        <div>
                            <span class="subtitle2">{{
                                $t("cp__reports_schedule_report_date_info")
                            }}</span>
                        </div>

                        <DateInput
                            v-model="report.startDate"
                            :placeholder="$t('cp__reports_schedule_report_select_dates')"
                            :locale="getLanguageCultureCode"
                            enableExternalValidation
                            :externalValidationError="$v.report.startDate.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.report.startDate)
                            "
                        />
                        <Dropdown
                            v-model="report.frequency"
                            :placeholder="$t('cp__reports_schedule_report_choose_frequency')"
                            :options="frequencies"
                            enableExternalValidation
                            :externalValidationError="$v.report.frequency.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.report.frequency)
                            "
                        />
                    </div>
                    <div class="report_field">
                        <div class="report_field_title">
                            <span>{{ $t("cp__reports_schedule_report_select_recipient") }}</span>
                        </div>

                        <div>
                            <span class="body2">{{
                                $t("cp__reports_schedule_report_recipient_info")
                            }}</span>
                        </div>

                        <EmailInputList
                            class="notification_emails"
                            v-model="report.recipientEmails"
                            :inputLabel="$t('cp__reports_schedule_report_email_input__label')"
                            enableExternalValidation
                            :externalValidationErrors="emailsErrorMap"
                            :externalValidationErrorMessages="emailsErrorMessagesMap"
                            :addAnotherLabel="$t('cp__add_another_label')"
                        />
                    </div>
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <SettingsFooter>
            <Button
                v-if="!isEdit"
                class="secondary_button"
                @click.native="createAndScheduleNew"
                :loading="isSaving"
                buttonStyle="secondary"
            >
                {{ $t("cp__reports_schedule_report_create_and_schedule_new") }}
            </Button>
            <Button
                v-if="isEdit"
                class="secondary_button"
                buttonStyle="secondary"
                @click.native="goToScheduledReports"
                :loading="isSaving"
            >
                {{ $t("cp__generic__cancel") }}
            </Button>
            <Button @click.native="save" :loading="isSaving" :disabled="isEdit && isDirty">
                {{ $t("cp__reports_schedule_report_save") }}
            </Button>
        </SettingsFooter>
    </GenericPage>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import EmailInputList from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/EmailInputList.vue";
import DateInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/DateInput.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import cloneDeep from "lodash.clonedeep";
import deepEqual from "deep-equal";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import getDashboardsList from "@/graphql/dashboards/getDashboardsList.gql";
import { resolveErrorMessage, validationMixin, scrollToFirstError } from "@/validation";
import { getScheduleReportSchema } from "@/validation/schemas/scheduleReport";
import SettingsBlock from "../components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "../components/Settings/Molecules/SettingsSection.vue";
import createScheduledReport from "../graphql/reports/createScheduledReport.gql";
import updateScheduledReport from "../graphql/reports/updateScheduledReport.gql";
import getScheduledReport from "../graphql/reports/getScheduledReport.gql";

export default {
    name: "ScheduleReport",
    mixins: [validationMixin],
    components: {
        GenericPage,
        Button,
        TextInput,
        SettingsForm,
        SettingsFooter,
        SettingsBlock,
        SettingsSection,
        Dropdown,
        DateInput,
        EmailInputList,
    },
    validations() {
        return getScheduleReportSchema(this.report);
    },
    computed: {
        isDirty() {
            return deepEqual(this.report, this.initialReport);
        },
        isEdit() {
            return !!this.$route.params.id;
        },
        ...mapGetters({
            getLanguageCultureCode: "LanguageStore/getLanguageCultureCode",
        }),
        backButtonUrl() {
            return "/scheduled-reports";
        },
        frequencies() {
            return [
                {
                    value: "DAILY",
                    displayValue: this.$t("cp__reports_schedule_report_frequency_daily"),
                },
                {
                    value: "WEEKLY",
                    displayValue: this.$t("cp__reports_schedule_report_frequency_weekly"),
                },
                {
                    value: "MONTHLY",
                    displayValue: this.$t("cp__reports_schedule_report_frequency_monthly"),
                },
                {
                    value: "YEARLY",
                    displayValue: this.$t("cp__reports_schedule_report_frequency_yearly"),
                },
            ];
        },
        emailsErrorMap() {
            return Object.values(this.$v.report.recipientEmails.$each.$iter).map(
                (email) => email.$error
            );
        },
        emailsErrorMessagesMap() {
            return Object.values(this.$v.report.recipientEmails.$each.$iter).map((email) =>
                this.resolveErrorMessage(email)
            );
        },
    },
    watch: {
        // eslint-disable-next-line func-names
        "report.dashboard": function () {
            if (!this.isLoading) {
                this.report.name = this.getEmailSubject();
            }
        },
    },
    data() {
        return {
            isLoading: true,
            isSaving: false,
            report: this.getInitialState(),
            dashboards: [],
            initialReport: null,
        };
    },
    methods: {
        resolveErrorMessage,
        scrollToFirstError,
        getEmailSubject() {
            const selectedDashboard = this.dashboards.find(
                (el) => el.value === this.report.dashboard
            );
            return selectedDashboard ? selectedDashboard.displayValue : "";
        },
        getInitialState() {
            return {
                dashboard: null,
                name: null,
                recipientEmails: [],
                startDate: null,
                frequency: null,
            };
        },
        async getDashboards() {
            const queryResult = await this.$apollo.query({ query: getDashboardsList });
            this.dashboards = queryResult.data.dashboardViews.map((item) => ({
                value: item.id,
                displayValue: item.name,
            }));
        },
        initSave() {
            if (this.report.recipientEmails.length === 0) {
                this.report.recipientEmails.push("");
            }
            this.$v.$touch();
        },
        async save() {
            this.initSave();
            if (!this.$v.$error) {
                let isCreated = false;
                if (this.isEdit) {
                    isCreated = await this.updateScheduledReport();
                } else {
                    isCreated = await this.createScheduledReport();
                }
                if (isCreated) {
                    this.goToScheduledReports();
                }
            } else {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__reports_schedule_report_message_error"),
                });
                this.$nextTick(() => {
                    this.scrollToFirstError(this.$el);
                });
            }
        },
        goToScheduledReports() {
            this.$router.push("/scheduled-reports");
        },
        async createAndScheduleNew() {
            this.initSave();
            if (!this.$v.$error) {
                const isCreated = await this.createScheduledReport();
                if (isCreated) {
                    this.report = this.getInitialState();
                    this.$v.$reset();
                    document.getElementsByClassName("report_field_title")[0]?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            } else {
                this.$nextTick(() => {
                    this.scrollToFirstError(this.$el);
                });
            }
        },
        async updateScheduledReport() {
            try {
                await this.$apollo.mutate({
                    mutation: updateScheduledReport,
                    variables: {
                        input: this.mapReportForSave(this.report),
                        reportId: this.report.id,
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__reports_schedule_report_update_title"),
                    message: this.$t("cp__reports_schedule_report_updated_message"),
                });
                return true;
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__reports_schedule_report_message_error"),
                });
                return false;
            }
        },
        async createScheduledReport() {
            try {
                await this.$apollo.mutate({
                    mutation: createScheduledReport,
                    variables: {
                        input: this.mapReportForSave(this.report),
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__reports_schedule_report_created_title"),
                    message: this.$t("cp__reports_schedule_report_created_message"),
                });
                return true;
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__reports_schedule_report_message_error"),
                });
                return false;
            }
        },
        mapReportForSave() {
            return {
                dashboardView: {
                    id: this.report.dashboard,
                },
                frequency: this.report.frequency,
                name: this.report.name ? this.report.name : this.getEmailSubject(),
                startDate: format(new Date(this.report.startDate), "MM/dd/yyyy"),
                recipients: this.report.recipientEmails.map((recipient) => ({
                    email: recipient,
                })),
            };
        },
        async getScheduledReport() {
            const queryResult = await this.$apollo.query({
                query: getScheduledReport,
                variables: { reportId: this.$route.params.id },
                fetchPolicy: "network-only",
            });
            const existingReport = queryResult.data.scheduledReport;
            existingReport.recipientEmails = existingReport.recipients.map((el) => el.email);
            existingReport.dashboard = existingReport.dashboardView.id;
            this.report = cloneDeep(existingReport);
            this.initialReport = cloneDeep(existingReport);
        },
    },
    async mounted() {
        await this.getDashboards();
        if (this.isEdit) {
            await this.getScheduledReport();
        }
        this.isLoading = false;
    },
};
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 170px;
    }
}

.settings_fieldset * {
    margin-bottom: 12px;
}

.secondary_button {
    margin-right: 12px;
}

.report_field {
    margin-bottom: 30px;
}

.report_field_title {
    @extend %subtitle3_style;
    margin-bottom: 4px;
}
</style>
