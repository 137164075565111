import { withParams } from "vuelidate";

export const validQualityMarkUrl = (options) =>
    withParams({ type: "validQualityMarkUrl", options }, () => {
        const { link } = options;
        const { mark } = options;

        // Testing: https://regex101.com/r/AsquyN/1
        const qualityMarkUrlPatterns = {
            feedbackCommunity: /(https:\/\/www\.feedbackcommunity\.nl\/)/,
            qShops: /(https:\/\/www\.qshops\.org\/)/,
            thuiswinkel: /(https:\/\/www\.thuiswinkel\.org\/)/,
            anvr: /https:\/\/www\.keurmerk\.info\//,
            keurmerkWebwinkelkeur: /(https:\/\/www\.webwinkelkeur\.nl\/)/,
            keurmerkSafeshops: /(https:\/\/label\.safeshops\.be\/)/,
            opiness: /(https:\/\/opiness\.nl\/review)\/[a-zA-Z]+\/?[a-zA-Z]+$/,
        };

        const qualityMarkUrlPattern = qualityMarkUrlPatterns[mark.code];
        return !mark.enabled || mark.readOnly ? true : qualityMarkUrlPattern.test(link);
    });
