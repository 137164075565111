import { helpers } from "vuelidate/lib/validators";
import { resolveImageFromDataUrl } from "@/helpers/resolveImageFromDataUrl";

export const hasProperHeightAndWidth = (options, optionsLabels = null) =>
    helpers.withParams({ type: "hasProperHeightAndWidth", options, optionsLabels }, (value) => {
        if (!options.maxHeight)
            throw Error(
                "Missing maxHeight property from hasProperHeightAndWidth validator options."
            );
        if (!options.maxWidth)
            throw Error(
                "Missing maxWidth property from hasProperHeightAndWidth validator options."
            );
        if (value.toString() !== "[object File]")
            throw Error(
                "Incorrect value being validated for hasProperHeightAndWidth. Expecting File."
            );

        return new Promise((resolve) => {
            resolveImageFromDataUrl(window.URL.createObjectURL(value)).then((image) =>
                resolve(!(image.height > options.maxHeight || image.width > options.maxWidth))
            );
        });
    });
